import React, { FormEvent } from "react";
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Checkbox } from '@fluentui/react';
import styled from "styled-components";
import { APIConfig } from "../../../../api";

const StyledConnection = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;

  & .horizontal-frame-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 0px 20px;
    position: relative;
    width: 100%;
  }

  & .field-8 {
    align-self: stretch !important;
    flex: 1 !important;
    flex-grow: 1 !important;
    width: unset !important;
    align-items: flex-end;
  }
  
  @media (max-width: 480px) {
    .horizontal-frame-2 {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1 100%;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      padding: 8px 20px;
      position: relative;
      width: 100%;
  }
`;

const options: IDropdownOption[] = [ 
  { key: 'text', text: 'Text' },
  { key: 'voice', text: 'Voice' },
  { key: 'asreceived', text: 'Same as received' },
];

type TInputOutputProps = {
  config: APIConfig,
  unsavedconfig: APIConfig,
  onChange: (value: React.SetStateAction<APIConfig>) => void;
};

export const InputOutput = ({config, unsavedconfig, onChange} : TInputOutputProps): JSX.Element => {
  return (
    <StyledConnection>
      <Separator alignContent="start">Input and Output Configuration</Separator>
      <div className="horizontal-frame-2">
        <Dropdown label="Reply Mode" placeholder="Select Reply Mode" options={options} className="field-8"
        defaultSelectedKey={config.replymode}
        onChange={(e, option, index) => { onChange({ ...unsavedconfig, replymode: option?.key as string}) }}
        />
        <Checkbox label="Show Metadata" defaultChecked={config.showmetadata} className="field-8"
        onChange={(e , checked) => { onChange({ ...unsavedconfig, showmetadata: checked as boolean }) }}/>
        <Checkbox label="Use multi-turn chat" defaultChecked={config.multiturn} className="field-8"
        onChange={(e, checked) => { onChange({ ...unsavedconfig, multiturn: checked as boolean }) }}/>
      </div>
      <div className="horizontal-frame-2">
        <TextField label="Intro Message" placeholder="Set initial message to customers" className="field-8" 
        defaultValue={config.intromessage}
        onChange={(e) => { onChange({ ...unsavedconfig, intromessage: e.currentTarget.value }) }}/>
        <TextField label="Responding Message" placeholder="Set quick reply while OpenAI works" className="field-8" 
        defaultValue={config.respondingmessage}
        onChange={(e) => { onChange({ ...unsavedconfig, respondingmessage: e.currentTarget.value }) }}/>
        <TextField label="Multi-turn Chat Past Messages Count" placeholder="Number of past messages to be added to the conversation" className="field-8" 
        defaultValue={config.multiturncount.toString()}
        onChange={(e) => { onChange({ ...unsavedconfig, multiturncount: Number(e.currentTarget.value) }) }}/>
      </div>
    </StyledConnection>
  );
};