import React from "react";
import styled from "styled-components";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Label } from '@fluentui/react/lib/Label';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

import styles from "../../../../components/common/Button.module.css";

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const StyledHeader = styled.header`
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex-flow: row wrap;

  & .buttons {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: right;
    padding: 8px 20px;
    position: relative;
    width: 100%;
  }

  & .frame {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    padding: 0px;
    position: relative;
  }

  .buttons-small {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
  }

  & .dropdown{

  }
  
  @media (max-width: 480px) {
    .buttons {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1 100%;
      flex-direction: column;
      gap: 8px;
      justify-content: right;
      padding: 8px 20px;
      position: relative;
      width: 100%;
    }
    .buttons-small {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      gap: 8px;
      justify-content: center;
      padding: 8px 20px;
      position: relative;
      width: 100%;
    }
    .frame {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-grow: 1;
      gap: 10px;
      padding: 0px;
      position: relative;
      font-size: 12px;
      justify-content: center;
    }
  }
  }
`;

type THeaderProps = {
  title: string,
  options: IDropdownOption[],
  onImportClick: () => void;
  onExportClick: () => void;
  onTemplateSelected: (index: string) => void;
};

export const Header = ({ title, options, onImportClick, onExportClick, onTemplateSelected }: THeaderProps): JSX.Element => {
  return (
    <StyledHeader>
      <div className="buttons">
        <Label styles={{ root: { fontSize: '36', fontWeight: 'bold' } }} className='frame'>{title}</Label>
        <Dropdown
          placeholder="Select a Template"
          options={options}
          styles={dropdownStyles}
          onChange={(ev, option, index) => onTemplateSelected(option?.key as string)}
        >
        </Dropdown>
        <div className="buttons-small">
          <PrimaryButton
            className={styles.shareButtonRoot}
            text="Import"
            onClick={onImportClick}
          />
          <PrimaryButton
            className={styles.shareButtonRoot}
            onClick={onExportClick}
            text="Export"
          />
        </div>
      </div>
    </StyledHeader>
  );
};