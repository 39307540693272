import React from "react";
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Checkbox } from '@fluentui/react';
import styled from "styled-components";
import { APIConfig } from "../../../../api";

const StyledConnection = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;

  & .horizontal-frame-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 0px 20px;
    position: relative;
    width: 100%;
  }

  & .field-8 {
    align-self: stretch !important;
    flex: 1 !important;
    flex-grow: 1 !important;
    width: unset !important;
    align-items: flex-end;
  }

  @media (max-width: 480px) {
    .horizontal-frame-2 {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1 100%;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      padding: 8px 20px;
      position: relative;
      width: 100%;
  }
`;

const options: IDropdownOption[] = [ //FAZER - Carregar templates
  { key: 'text', text: 'Text' },
  { key: 'voice', text: 'Voice' },
  { key: 'received', text: 'Same as received' },
];

type TOpenAIProps = {
  config: APIConfig,
  unsavedconfig: APIConfig,
  onChange: (value: React.SetStateAction<APIConfig>) => void;
};

export const OpenAi = ({config, unsavedconfig, onChange} : TOpenAIProps): JSX.Element => {
  return (
    <StyledConnection>
      <Separator alignContent="start">OpenAI Configuration</Separator>
      <div className="horizontal-frame-2">
        <TextField label="Max Response" placeholder="Set max number of response tokens" className="field-8" 
        defaultValue={config.maxresponse}
        onChange={(e) => { onChange({ ...unsavedconfig, maxresponse: e.currentTarget.value }) }}/>
        <TextField label="Temperature" placeholder="Set model temperature" className="field-8" 
        defaultValue={config.temperature}
        onChange={(e) => { onChange({ ...unsavedconfig, temperature: e.currentTarget.value }) }}/>
      </div>
      <div className="horizontal-frame-2">
        <TextField label="Frequency Penalty" placeholder="Set Frequency Penalty" className="field-8" 
        defaultValue={config.frequencypenalty}
        onChange={(e) => { onChange({ ...unsavedconfig, frequencypenalty: e.currentTarget.value }) }}/>
        <TextField label="Presence Penalty" placeholder="Set Presence Penalty" className="field-8" 
        defaultValue={config.presencepenalty}
        onChange={(e) => { onChange({ ...unsavedconfig, presencepenalty: e.currentTarget.value }) }}/>
      </div>
      <div className="horizontal-frame-2">
        <TextField multiline rows={3} resizable label="System Message" placeholder="Enter your Chat instructions" className="field-8" 
        defaultValue={config.systemmessage}
        onChange={(e) => { onChange({ ...unsavedconfig, systemmessage: e.currentTarget.value }) }}/>
       </div>
      <div className="horizontal-frame-2">
        <TextField multiline rows={3} resizable label="Examples" placeholder="Enter examples to be followed" className="field-8" 
        defaultValue={config.examples}
        onChange={(e) => { onChange({ ...unsavedconfig, examples: e.currentTarget.value }) }}/>
        <TextField multiline rows={3} resizable label="Customer Data" placeholder="Enter customer data to be used" className="field-8" 
        defaultValue={config.customerdata}
        onChange={(e) => { onChange({ ...unsavedconfig, customerdata: e.currentTarget.value }) }}/>
      </div>
    </StyledConnection>
  );
};