import React from "react";
import styled from "styled-components";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

import styles from "../../../../components/common/Button.module.css";

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const options: IDropdownOption[] = [ //FAZER - Carregar templates
  { key: 'fruitsHeader', text: 'Fruits', itemType: DropdownMenuItemType.Header },
  { key: 'apple', text: 'Apple' },
  { key: 'banana', text: 'Banana' },
  { key: 'orange', text: 'Orange', disabled: true },
  { key: 'grape', text: 'Grape' },
  { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
  { key: 'vegetablesHeader', text: 'Vegetables', itemType: DropdownMenuItemType.Header },
  { key: 'broccoli', text: 'Broccoli' },
  { key: 'carrot', text: 'Carrot' },
  { key: 'lettuce', text: 'Lettuce' },
];


const StyledHeader = styled.header`
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;

  & .buttons {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: right;
    padding: 8px 20px;
    position: relative;
    width: 100%;
  }

  & .frame {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    padding: 10px;
    position: relative;
  }

  & .dropdown{

  }
  }
`;

type TFooterProps = {
  onSaveClick: () => void;
  onDismissClick: () => void;
};

export const Footer = ({onSaveClick, onDismissClick}: TFooterProps): JSX.Element => {
  return (
    <StyledHeader>
      <div className="buttons">
        <DefaultButton
          text="Discard"
          onClick={onDismissClick}
        />
        <PrimaryButton
          className={styles.shareButtonRoot}
          onClick={onSaveClick}
          text="Save"
        />
      </div>
    </StyledHeader>
  );
};